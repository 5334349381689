module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gtag-virtual-596e70ac06/3/buildhome/.yarn/berry/cache/gatsby-plugin-gtag-npm-1.0.13-387068c71b-10c0.zip/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-640WERC942","head":true,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-7fc46da5f5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
